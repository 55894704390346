/* eslint-disable react/prop-types */
import get from 'lodash/get'
import isString from 'lodash/isString'
import React from 'react'

const ImageField = ({ source, record = {}, height, width, circle }) => {
  const style = {
    margin: '6px',
    textAlign: 'center',
    maxHeight: '200px',
    maxWidth: '300px',
  }

  if (height) {
    style.height = `${height}`
  }
  if (width) {
    style.width = `${width}`
  }

  style.borderRadius = circle ? style.width : 0
  return (
    <img
      src={isString(record) ? record : get(record, source)}
      alt=""
      style={style}
    />
  )
}

export default ImageField
