import * as React from 'react'
import { AppBar as RAAppBar, MenuItemLink } from 'react-admin'
import { UserMenu as RAUserMenu } from 'react-admin'
import PersonIcon from '@material-ui/icons/Person'

const UserMenu = props => {
  return (
    <RAUserMenu {...props}>
      <MenuItemLink
        to="/profile"
        primaryText="Saját fiók"
        leftIcon={<PersonIcon />}
      />
    </RAUserMenu>
  )
}

const AppBar = props => {
  return <RAAppBar {...props} userMenu={<UserMenu />}></RAAppBar>
}

export default AppBar
