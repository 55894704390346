/* eslint-disable react/prop-types */
import get from 'lodash/get'
import isString from 'lodash/isString'
import React from 'react'

const VideoField = ({ source, record = {} }) => {
  return (
    <video
      src={isString(record) ? record : get(record, source)}
      style={{ maxWidth: 400, maxHeight: 400 }}
      controls
    />
  )
}

export default VideoField
