/* eslint-disable react/prop-types */
import { required } from 'ra-core'
import React from 'react'
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  DateInput,
} from 'react-admin'
import SaveOnlyEditToolBar from 'tools/toolbars/Toolbar'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Aside from 'resources/Aside'
import ImageField from 'tools/ImageField'

const ProfilePictureField = ({ source, record }) => {
  if (record.photoURL) {
    return (
      <ImageField
        record={record.photoURL}
        source={source}
        label="Profil kép"
        width="50px"
        height="50px"
        circle
      ></ImageField>
    )
  }
  return (
    <ImageField
      record="profile-icon.png"
      source={source}
      label="Profil kép"
      width="50px"
      height="50px"
      circle
    ></ImageField>
  )
}

const ProviderField = ({ source, record }) => {
  return (
    <ImageField
      record={`${record.provider}.png`}
      source={source}
      label="Szolgáltató"
      width="50px"
      height="50px"
      circle
    ></ImageField>
  )
}

const UserForm = props => (
  <SimpleForm {...props} redirect="edit" toolbar={<SaveOnlyEditToolBar />}>
    <TextInput
      source="id"
      disabled
      style={{ width: '20rem' }}
      validate={required()}
    />
    <TextInput source="username" label="Felhasználónév" validate={required()} />
    <TextInput source="email" type="email" label="Email" disabled />
    <BooleanInput
      disabled
      label="Email megerősítve"
      source="emailVerified"
      options={{
        checkedIcon: <CheckCircleIcon />,
      }}
    />
    <BooleanInput
      disabled
      label="Hírlevél"
      source="newsletter"
      options={{
        checkedIcon: <CheckCircleIcon />,
      }}
    />

    <DateInput label="Születési idő" source="birthday" disabled />
    <BooleanInput
      label="Adatok elrejtve"
      source="datahidden"
      disabled
      options={{
        checkedIcon: <CheckCircleIcon />,
      }}
    />
    <BooleanInput label="Komment tiltva" source="commentDisabled" />
    <BooleanInput label="Pin rögzítés tiltva" source="pinDisabled" />
    <BooleanInput label="Toplistából kizárva" source="challengeDisabled" />
  </SimpleForm>
)

export const create = props => (
  <Create aside={<Aside basePath={props.resource} />} {...props}>
    <UserForm />
  </Create>
)

export const edit = props => (
  <Edit aside={<Aside basePath={props.resource} />} {...props} undoable={false}>
    <UserForm />
  </Edit>
)

const UserFilter = props => (
  <Filter {...props}>
    <TextInput source="username" label="Felhasználónév" resettable alwaysOn />

    <TextInput label="Email" source="email" resettable alwaysOn />
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filters={<UserFilter />}
    hasCreate={false}
    sort={{ filed: 'registrationDateTime', order: 'DESC' }}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="username" label="Felhasználónév" />
      <TextField source="email" />
      <BooleanField
        label="Hírlevél"
        source="newsletter"
        options={{
          checkedIcon: <CheckCircleIcon />,
        }}
      />

      <ProfilePictureField source="photoURL" label="Profil kép" />

      <ProviderField source="provider" label="Szolgáltató" />

      <DateField source="registrationDateTime" label="Regisztráció" showTime />
      <DateField
        source="lastLoginDateTime"
        label="Utolsó bejelentkezés"
        showTime
      />
    </Datagrid>
  </List>
)
