import { required } from 'ra-core'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  BooleanField,
  DateTimeInput,
  DateField,
  DateInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectField,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import { makeStyles } from '@material-ui/core/styles'
import ImageField from 'tools/ImageField'
import Aside from 'resources/Aside'

const typeChoices = [
  { id: 'article', name: 'Cikkek' },
  { id: 'nyomsuli', name: 'Nyomsuli' },
  { id: 'default', name: 'Alapértelmezett' },
]
// richtext input can't be styled properly: https://github.com/marmelab/react-admin/issues/3070
const useStyles = makeStyles({
  rtInput: {
    minHeight: '500px',
    background: 'rgba(0, 0, 0, 0.04)',
  },
})

const ArticleForm = props => {
  const classes = useStyles()
  return (
    <SimpleForm {...props} redirect="edit">
      <TextInput source="id" disabled style={{ width: '10rem' }} />

      <TextInput
        validate={required()}
        source="title"
        style={{ width: '40rem' }}
        label="Cím"
      />
      <TextInput
        source="lead"
        label="Lead"
        style={{ width: '40rem' }}
        validate={required()}
      />

      <ImageInput
        source="image"
        label="Kép"
        multiple={false}
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField />
      </ImageInput>

      <ImageInput
        source="mobileImage"
        label="Mobil kép"
        multiple={false}
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField />
      </ImageInput>

      <SelectInput
        choices={typeChoices}
        source="articleType"
        label="Megjelenési hely"
        initialValue={'default'}
      />

      <RichTextInput
        validate={required()}
        source="content"
        label="Tartalom"
        variant={classes.rtInput}
        options={{
          modules: {
            history: {
              delay: 2000,
              maxStack: 500,
              userOnly: true,
            },
            toolbar: [
              [{ header: ['1', '2', '3', false] }],
              ['bold', 'italic', 'underline', 'link'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['clean'],
              ['image', 'video'],
            ],
          },
        }}
      />

      <DateTimeInput
        source="publishedFrom"
        resettable
        label="Publikálás kezdete"
      />
      <DateTimeInput source="publishedTo" resettable label="Publikálás vége" />
      <BooleanInput
        source="isPublished"
        label="Publikálva"
        defaultValue={true}
      ></BooleanInput>
      <BooleanInput source="isDeleted" label="Törölve" />

      <TextInput
        source="lastUpdatedBy.username"
        disabled
        label="Utoljára módosította"
      />

      <ReferenceArrayInput
        reference="animals"
        source="animals"
        label="Állat"
        sort={{ field: 'name', order: 'ASC' }}
        perPage={100}
        filterToQuery={searchText => ({
          name_like: searchText,
        })}
      >
        <AutocompleteArrayInput source="id " optionText="name" />
      </ReferenceArrayInput>
      <DateTimeInput label="Létrehozva" source="createdAt" disabled />
      <DateTimeInput label="Módosítva" source="updatedAt" disabled />
    </SimpleForm>
  )
}

export const create = props => (
  <Create aside={<Aside basePath={props.resource} />} {...props}>
    <ArticleForm />
  </Create>
)

export const edit = props => (
  <Edit aside={<Aside basePath={props.resource} />} {...props} undoable={false}>
    <ArticleForm />
  </Edit>
)

const ArticleFilter = props => (
  <Filter {...props}>
    <TextInput label="Cím" source="title_like" alwaysOn />

    <BooleanInput source="isDeleted" label="Törölt" alwaysOn />

    <ReferenceInput
      label="Szerző"
      source="createdBy.id"
      reference="adminUsers"
      alwaysOn
      shouldRenderSuggestions={false}
    >
      <SelectInput source="createdBy.name" optionText="name" resettable />
    </ReferenceInput>

    <DateInput label="Létrehozva ezután" source="createdAt_after" alwaysOn />
    <DateInput label="Publikálva ettől" source="publishedFrom_after" alwaysOn />

    <SelectInput
      choices={typeChoices}
      source="articleType"
      label="Megjelenési hely"
      resettable
      alwaysOn
    />
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filters={<ArticleFilter />}
    sort={{ filed: 'createdAt', order: 'DESC' }}
    exporter={false}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="title" label="Cím" />
      <TextField source="lead" label="Alcím" />
      <BooleanField source="isDeleted" label="Törölve" />
      <BooleanField source="isPublished" label="Publikálva" />
      <DateField source="publishedFrom" label="Publikálás kezdete" />
      <DateField source="publishedTo" label="Publikálás vége" />
      <TextField source="createdBy.name" label="Szerző" />
      <SelectField
        source="articleType"
        choices={typeChoices}
        label="Megjelenítési hely"
      />
    </Datagrid>
  </List>
)
