import React from 'react'
import {
  Edit,
  Loading,
  PasswordInput,
  required,
  SimpleForm,
  TextInput,
  useGetIdentity,
} from 'react-admin'
import Toolbar from './toolbars/Toolbar'

export default function ProfileForm(props) {
  const { identity, loading } = useGetIdentity()
  if (loading || !identity) {
    return <Loading></Loading>
  }

  return (
    <Edit
      resource="adminUsers"
      id={identity.id}
      title="Saját profil"
      basePath="profile"
      {...props}
    >
      <SimpleForm toolbar={<Toolbar />}>
        <TextInput source="username" validate={[required()]} />
        <PasswordInput source="password" label="New password" allowEmpty />
      </SimpleForm>
    </Edit>
  )
}
