import envConfig from 'envConfig'
import simpleRestProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'react-admin'

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = localStorage.getItem('token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchUtils.fetchJson(url, options)
}

const baseDataProvider = simpleRestProvider(
  envConfig.ApiUrl,
  httpClient,
  'X-Total-Count'
)

const dataProvider = {
  ...baseDataProvider,
  update: async (resource, params) => {
    const { data } = params

    if (resource === 'blocks') {
      const blockItems = await Promise.all(
        data.blockItems.map(async item => {
          return {
            ...item,
            image:
              item.image && item.image.rawFile
                ? await convertFileToBase64(item.image)
                : item.image,
            mobileImage:
              item.mobileImage && item.mobileImage.rawFile
                ? await convertFileToBase64(item.mobileImage)
                : item.mobileImage,
          }
        })
      )
      return baseDataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          blockItems: blockItems,
        },
      })
    } else if (
      ['footprints', 'places', 'traces'].includes(resource) &&
      data.images
    ) {
      const images = await Promise.all(
        data.images.map(async i => {
          return i && i.rawFile ? await convertFileToBase64(i) : i
        })
      )

      if (data.videos) {
        data.videos = await Promise.all(
          data.videos.map(async ({ video, thumbnail }) => {
            let vid = video
            let thumb = thumbnail

            if (video && video.rawFile) {
              vid = await convertFileToBase64(video)
            }
            if (thumbnail && thumbnail.rawFile) {
              thumb = await convertFileToBase64(thumbnail)
            }

            return {
              video: vid,
              thumbnail: thumb,
            }
          })
        )
      }

      return baseDataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          images: images,
        },
      })
    } else if (['articles', 'events'].includes(resource)) {
      const image =
        data.image && data.image.rawFile
          ? await convertFileToBase64(data.image)
          : data.image

      const mobileImage =
        data.mobileImage && data.mobileImage.rawFile
          ? await convertFileToBase64(data.mobileImage)
          : data.mobileImage

      return baseDataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          image: image,
          mobileImage: mobileImage,
        },
      })
    } else if (resource === 'animals') {
      const thumbnail =
        data.thumbnail && data.thumbnail.rawFile
          ? await convertFileToBase64(data.thumbnail)
          : data.thumbnail

      const mainImage =
        data.mainImage && data.mainImage.rawFile
          ? await convertFileToBase64(data.mainImage)
          : data.mainImage

      const images = await Promise.all(
        data.images.map(async i => {
          return i && i.url && i.url.rawFile
            ? { url: await convertFileToBase64(i.url), order: i.order }
            : i
        })
      )
      const footPrintDrawings = await Promise.all(
        data.footPrintDrawings.map(async i => {
          return i && i.url && i.url.rawFile
            ? {
                url: await convertFileToBase64(i.url),
                order: i.order,
                name: i.name,
              }
            : i
        })
      )

      return baseDataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          thumbnail,
          mainImage,
          images,
          footPrintDrawings,
        },
      })
    } else {
      // fallback to the default implementation
      return baseDataProvider.update(resource, params)
    }
  },
  create: async (resource, params) => {
    const { data } = params
    if (resource === 'blocks') {
      const blockItems = await Promise.all(
        data.blockItems.map(async item => {
          return {
            ...item,
            image:
              item.image && item.image.rawFile
                ? await convertFileToBase64(item.image)
                : item.image,
            mobileImage:
              item.mobileImage && item.mobileImage.rawFile
                ? await convertFileToBase64(item.mobileImage)
                : item.mobileImage,
          }
        })
      )
      return baseDataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          blockItems: blockItems,
        },
      })
      // fallback to the default implementation
    } else if (
      ['footprints', 'places', 'traces'].includes(resource) &&
      data.images
    ) {
      const images = await Promise.all(
        data.images.map(async i => {
          return i && i.rawFile ? await convertFileToBase64(i) : i
        })
      )

      if (data.videos) {
        data.videos = await Promise.all(
          data.videos.map(async ({ video, thumbnail }) => {
            let vid = video
            let thumb = thumbnail

            if (video && video.rawFile) {
              vid = await convertFileToBase64(video)
            }
            if (thumbnail && thumbnail.rawFile) {
              thumb = await convertFileToBase64(thumbnail)
            }

            return {
              video: vid,
              thumbnail: thumb,
            }
          })
        )
      }

      return baseDataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          images: images,
        },
      })
    } else if (['articles', 'events'].includes(resource)) {
      const image =
        data.image && data.image.rawFile
          ? await convertFileToBase64(data.image)
          : data.image

      const mobileImage =
        data.mobileImage && data.mobileImage.rawFile
          ? await convertFileToBase64(data.mobileImage)
          : data.mobileImage

      return baseDataProvider.create(resource, {
        ...params,
        data: {
          ...params.data,
          image: image,
          mobileImage: mobileImage,
        },
      })
    } else {
      return baseDataProvider.create(resource, params)
    }
  },
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject

    reader.readAsDataURL(file.rawFile)
  })

export default dataProvider
