import { required } from 'ra-core'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  PasswordInput,
  BooleanInput,
  BooleanField,
  DateTimeInput,
  DateField,
  DateInput,
} from 'react-admin'
import Aside from 'resources/Aside'

const UserForm = props => (
  <SimpleForm {...props} redirect="edit">
    <TextInput source="id" disabled style={{ width: '20rem' }} />
    <TextInput source="username" label="Felhasználónév" validate={required()} />
    <TextInput source="name" label="Név" />
    <TextInput
      source="email"
      type="email"
      label="Email"
      validate={required()}
    />
    <BooleanInput label="Superadmin" source="isAdmin" />
    <PasswordInput label="Jelszó" source="password" />
    <BooleanInput label="Zárolva" source="isLocked" />
    <BooleanInput label="Törölve" source="isDeleted" />
    <DateTimeInput label="Létrehozva" source="createdAt" disabled />
    <DateTimeInput label="Módosítva" source="updatedAt" disabled />
  </SimpleForm>
)

export const create = props => (
  <Create aside={<Aside basePath={props.resource} />} {...props}>
    <UserForm />
  </Create>
)

export const edit = props => (
  <Edit aside={<Aside basePath={props.resource} />} {...props} undoable={false}>
    <UserForm />
  </Edit>
)

export const show = props => (
  <Show {...props}>
    <UserForm />
  </Show>
)

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Felhasználónév" source="username_like" alwaysOn />
    <TextInput label="Email" source="email_like" alwaysOn />
    <BooleanInput label="Zárolva" source="isLocked" alwaysOn />
    <BooleanInput label="Törölve" source="isDeleted" alwaysOn />

    <BooleanInput label="Superadmin" source="isAdmin" alwaysOn />
    <DateInput
      label="Létrehozva ezután"
      resettable={true}
      source="createdAt_after"
      clearAlwaysVisible={true}
      alwaysOn
    />
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filters={<UserFilter />}
    exporter={false}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="username" label="Felhasználónév" />
      <TextField source="email" />
      <BooleanField source="isLocked" label="Zárolva" />
      <BooleanField source="isDeleted" label="Törölve" />
      <BooleanField source="isAdmin" label="Superadmin" />
      <DateField source="createdAt" label="Létrehozva" />
    </Datagrid>
  </List>
)
