import React from 'react'
import ReactDOM from 'react-dom'
import './envConfig'

import './index.css'
import App from './App'

document.addEventListener('wheel', function() {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur()
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
