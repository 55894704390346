/* eslint-disable react/prop-types */
import {
  FormDataConsumer,
  maxValue,
  minValue,
  required,
  useTranslate,
} from 'ra-core'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  BooleanField,
  DateTimeInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  NumberField,
  DateField,
  ImageInput,
  DateInput,
} from 'react-admin'
import Aside from 'resources/Aside'
import { OrderedFormIterator } from 'tools/OrderedFormIterator'
import ImageField from '../tools/ImageField'

const transform = data => {
  return {
    ...data,
    blockItems: (data.blockItems || []).map((item, order) => {
      // These fields are assigned to null on the items after creating and reordering them.
      // But they are required columns in the db
      if (item.createdAt === null) {
        delete item.createdAt
      }
      if (item.updatedAt === null) {
        delete item.updatedAt
      }
      if (item.isDeleted === null) {
        delete item.isDeleted
      }
      return {
        ...item,
        order: order + 1,
      }
    }),
  }
}

const BlockForm = props => {
  const translate = useTranslate()

  return (
    <SimpleForm {...props} redirect="edit">
      <TextInput source="id" disabled style={{ width: '20rem' }} />
      <TextInput source="title" fullWidth label="Cím" />
      <BooleanInput source="isDeleted" label="Törölve" />
      <NumberInput
        source="order"
        validate={[required(), minValue(1)]}
        step={1}
        min={1}
        label="Sorrend"
      ></NumberInput>

      <NumberInput
        source="refreshRate"
        validate={[required(), minValue(1), maxValue(21600)]}
        step={1}
        defaultValue={600}
        min={1}
        max={21600}
        label="Újratöltési periódus (s)"
      ></NumberInput>

      <SelectInput
        source="blockType"
        label="Blokk típus"
        validate={required()}
        choices={[
          { id: 'carousel', name: 'Slider' },
          { id: 'list', name: 'Lista' },
        ]}
      />

      <ArrayInput source="blockItems" label="Blokk elemek" fullWidth>
        <OrderedFormIterator translate={translate}>
          <TextInput
            source="id"
            disabled
            style={{ width: '20rem' }}
            label="Id"
          />
          <SelectInput
            source="referenceType"
            label="Tartalom típus"
            validate={[required()]}
            choices={[
              { id: 'articles', name: 'Cikk' },
              { id: 'events', name: 'Esemény' },
              { id: 'nyomsuli', name: 'Nyomsuli' },
              { id: 'footprints', name: 'Lábnyom' },
              { id: 'traces', name: 'Egyéb nyom' },
              { id: 'places', name: 'Érdekesség' },
              { id: 'animals', name: 'Állat' },
              { id: 'comments', name: 'Hozzászólás' },
              { id: 'url', name: 'Url' },
              { id: 'users', name: 'Nyomkeresők' },
            ]}
          />
          <ImageInput
            source="image"
            label="Kép"
            multiple={false}
            accept="image/*"
            maxSize={5000000}
          >
            <ImageField />
          </ImageInput>

          <ImageInput
            source="mobileImage"
            label="Mobil kép"
            multiple={false}
            accept="image/*"
            maxSize={5000000}
          >
            <ImageField />
          </ImageInput>

          <DateTimeInput label="Mettől" source="publishedFrom" />
          <DateTimeInput label="Meddig" source="publishedTo" />
          <BooleanInput
            source="isPublished"
            label="Publikálva"
            defaultValue={true}
          ></BooleanInput>

          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              if (!scopedFormData) {
                return null
              }
              if (scopedFormData.referenceType === 'url') {
                return (
                  <TextInput
                    source={getSource('referenceUrl')}
                    validate={[required()]}
                    label={'url'}
                  ></TextInput>
                )
              }

              return (
                <>
                  <SelectInput
                    source={getSource('fetchType')}
                    validate={required()}
                    label="Megjelenített tartalom"
                    style={{ marginRight: '1rem', width: '14rem' }}
                    choices={
                      ['places', 'traces', 'footprints', 'users'].includes(
                        scopedFormData.referenceType
                      )
                        ? [
                            { id: 'top', name: 'Top' },
                            { id: 'recent', name: 'Friss' },
                            { id: 'pinned', name: 'Kitűzött' },
                          ]
                        : scopedFormData.referenceType === 'animals'
                        ? [{ id: 'pinned', name: 'Kitűzött' }]
                        : scopedFormData.referenceType === 'animals'
                        ? [{ id: 'top', name: 'Top' }]
                        : [
                            { id: 'recent', name: 'Friss' },
                            { id: 'pinned', name: 'Kitűzött' },
                          ]
                    }
                  />
                  {scopedFormData.fetchType === 'pinned' && (
                    <ReferenceInput
                      fullWidth
                      reference={
                        scopedFormData.referenceType === 'nyomsuli'
                          ? 'articles'
                          : scopedFormData.referenceType
                      }
                      source={getSource('referenceId')}
                      label="Kitűzött tartalom"
                      filter={
                        ['articles', 'events', 'nyomsuli'].includes(
                          scopedFormData.referenceType
                        )
                          ? {
                              isPublished: true,
                              isDeleted: false,
                              ...(scopedFormData.referenceType === 'articles'
                                ? {
                                    articleType_in: ['article', 'default'],
                                  }
                                : scopedFormData.referenceType === 'nyomsuli'
                                ? { articleType: 'nyomsuli' }
                                : {}),
                            }
                          : scopedFormData.referenceType === 'animals'
                          ? { language: 'HU' }
                          : ''
                      }
                      filterToQuery={searchText => ({
                        [scopedFormData.referenceType === 'animals'
                          ? 'name_like'
                          : scopedFormData.referenceType === 'users'
                          ? 'username_like'
                          : 'title_like']: searchText,
                      })}
                    >
                      <AutocompleteInput
                        resettable
                        optionText={
                          scopedFormData.referenceType === 'animals'
                            ? 'name'
                            : scopedFormData.referenceType === 'users'
                            ? 'username'
                            : 'title'
                        }
                      />
                    </ReferenceInput>
                  )}
                  {scopedFormData.fetchType === 'top' && (
                    <>
                      <NumberInput
                        source={getSource('topCount')}
                        label="Top #"
                        validate={[required(), minValue(1), maxValue(15)]}
                        min={1}
                        step={1}
                        defaultValue={3}
                        max={15}
                        disabled
                      />
                      <DateInput
                        source={getSource('topFromDate')}
                        label="Pontszámítási időszak kezdete"
                      />
                      <DateInput
                        source={getSource('topToDate')}
                        label="Pontzámítási időszak vége"
                      />
                    </>
                  )}
                  {scopedFormData.fetchType === 'recent' && (
                    <NumberInput
                      source={getSource('topCount')}
                      label="Időrend #"
                      validate={[minValue(1), maxValue(15)]}
                      min={1}
                      step={1}
                      initialValue={1}
                      max={10}
                      style={{ width: '8rem' }}
                    />
                  )}
                  {scopedFormData.referenceType != 'url' && (
                    <TextInput
                      source={getSource('referenceTitle')}
                      disabled
                      style={{ width: '100%' }}
                      label="Kitűzött referencia neve"
                    />
                  )}
                </>
              )
            }}
          </FormDataConsumer>
        </OrderedFormIterator>
      </ArrayInput>

      <TextInput
        source="lastUpdatedBy.username"
        disabled
        label="Utoljára módosította"
      />

      <DateTimeInput label="Létrehozva" source="createdAt" disabled />
      <DateTimeInput label="Módosítva" source="updatedAt" disabled />
    </SimpleForm>
  )
}

export const create = props => (
  <Create
    aside={<Aside basePath={props.resource} />}
    {...props}
    transform={transform}
  >
    <BlockForm />
  </Create>
)

export const edit = props => (
  <Edit
    aside={<Aside basePath={props.resource} />}
    {...props}
    transform={transform}
  >
    <BlockForm />
  </Edit>
)

const BlockFilter = props => (
  <Filter {...props}>
    <TextInput label="Cím" source="title_like" alwaysOn />
    <BooleanInput source="isDeleted" label="Törölve" alwaysOn />
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filters={<BlockFilter />}
    filterDefaultValues={{ isDeleted: false }}
    exporter={false}
    sort={{ field: 'order', order: 'ASC' }}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="title" label="Cím" />
      <BooleanField source="isDeleted" label="Törölve" />
      <NumberField source="order" label="Sorrend" />
      <TextField source="createdBy.username" label="Létrehozta" />
      <DateField source="createdAt" label="Létrehozva" />
    </Datagrid>
  </List>
)
