import React from 'react'
import {
  ArrayInput,
  Create,
  Datagrid,
  Edit,
  Filter,
  FormDataConsumer,
  FormTab,
  ImageInput,
  List,
  maxValue,
  minValue,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,

  // ReferenceInput,

  // AutocompleteInput,
} from 'react-admin'

import Aside from 'resources/Aside'
import ImageField from 'tools/ImageField'
import ExtendedPagination from 'tools/Pagination'

const hazardousChoices = [
  { id: '0', name: 'Nem ártalmas' },
  { id: '1', name: 'Kissé ártalmas' },
  { id: '2', name: 'Veszélyes' },
  { id: '3', name: 'Nagyon veszélyes' },
]

const dietaryClassChoices = [
  { id: 'insectivore', name: 'Rovarevő' },
  { id: 'herbivore', name: 'Növényevő' },
  { id: 'carnivore', name: 'Ragadozó' },
  { id: 'omnivore', name: 'Mindenevő' },
  { id: 'rodent', name: 'Rágcsáló' },
]

const AnimalCreateForm = props => {
  return (
    <SimpleForm {...props}>
      <TextInput
        source="animalKey"
        label="Animal key"
        validate={required()}
        style={{ width: 200, marginRight: 20 }}
      />
      <>
        <TextInput
          source="nameHu"
          label="Magyar név"
          validate={required()}
          style={{ width: 200, marginRight: 20 }}
        />

        <TextInput
          source="nameEn"
          label="Angol név"
          validate={required()}
          style={{ width: 200, marginRight: 20 }}
        />

        <TextInput
          source="nameLatin"
          label="Latin név"
          validate={required()}
          style={{ width: 200, marginRight: 20 }}
        />
      </>
      <>
        <TextInput
          source="height"
          label="Magasság"
          validate={required()}
          style={{ width: 200, marginRight: 20 }}
        />
        <TextInput
          source="length"
          label="Hossz"
          validate={required()}
          style={{ width: 200, marginRight: 20 }}
        />
        <TextInput
          source="weight"
          label="Súly"
          validate={required()}
          style={{ width: 200, marginRight: 20 }}
        />
      </>
      <>
        <SelectInput
          source="dietaryClass"
          validate={required()}
          label="Táplálkozási osztály"
          choices={dietaryClassChoices}
          style={{ width: 200, marginRight: 20 }}
        />

        <SelectInput
          choices={hazardousChoices}
          source="hazardous"
          validate={required()}
          label="Veszélyesség"
          style={{ width: 50, marginRight: 20 }}
        />
      </>
    </SimpleForm>
  )
}

const AnimalForm = props => (
  <TabbedForm {...props} redirect="edit">
    <FormTab label="Alap adatok">
      <>
        <TextInput
          source="id"
          label="ID"
          disabled
          style={{ width: 80, marginRight: 20 }}
        />
        <TextInput
          source="language"
          label="Nyelv"
          disabled
          style={{ width: 100, marginRight: 20 }}
        />
        <TextInput
          source="animalKey"
          disabled
          label="key"
          style={{ width: 200, marginRight: 20 }}
        />
      </>

      <>
        <TextInput
          source="name"
          label="Név"
          style={{ width: 200, marginRight: 20 }}
        />
        <TextInput
          source="nameLatin"
          label="Latin név"
          style={{ width: 200, marginRight: 20 }}
        />
      </>

      <>
        <TextInput
          source="height"
          label="Magasság"
          style={{ width: 200, marginRight: 20 }}
        />
        <TextInput
          source="length"
          label="Hossz"
          style={{ width: 200, marginRight: 20 }}
        />
        <TextInput
          source="weight"
          label="Súly"
          style={{ width: 200, marginRight: 20 }}
        />
      </>

      <>
        <TextInput
          source="diet"
          label="Táplálkozási forma"
          style={{ width: 200, marginRight: 20 }}
        />
        <SelectInput
          source="dietaryClass"
          label="Táplálkozási osztály"
          choices={dietaryClassChoices}
          style={{ width: 200, marginRight: 20 }}
        />
      </>
      <TextInput
        source="active"
        label="Aktivitás"
        multiline
        style={{ width: 600, marginRight: 20 }}
      />

      <TextInput
        source="location"
        label="Földrajzi előfordulási hely"
        multiline
        style={{ width: 600, marginRight: 20 }}
      />

      <TextInput
        source="habitat"
        label="Élőhely"
        multiline
        style={{ width: 600, marginRight: 20 }}
      />

      <TextInput
        source="animalProtected"
        label="Védettség"
        multiline
        style={{ width: 200, marginRight: 20 }}
      />
      <SelectInput
        choices={hazardousChoices}
        source="hazardous"
        label="Veszélyesség"
        style={{ width: 50, marginRight: 20 }}
      />
    </FormTab>

    <FormTab label="Egyéb adatok">
      <TextInput
        source="appereanceText"
        label="Megjelenés"
        multiline
        fullWidth
      />

      <TextInput
        source="footprintDesc"
        label="Lábnyom jellemzői"
        multiline
        fullWidth
      />

      <TextInput
        source="footprintDetailsText"
        label="Lábnyom részletei"
        multiline
        fullWidth
      />

      <ArrayInput source="footPrintDrawings" label="Nyom rajzok">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              if (!scopedFormData) {
                return null
              }
              return (
                <TextInput
                  source={getSource('name')}
                  label="Lábnyom rajz elnevezése"
                  validate={required()}
                  style={{ marginRight: '20px' }}
                  disabled={scopedFormData.url}
                />
              )
            }}
          </FormDataConsumer>
          <NumberInput
            source="order"
            label="Sorrend"
            max={9}
            min={0}
            validate={[minValue(0), maxValue(9)]}
          />

          <ImageInput
            source="url"
            label="Nyom rajz"
            multiple={false}
            accept="image/png"
          >
            <ImageField />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>

      <TextInput
        source="locationLongText"
        label="Előfordulás"
        multiline
        fullWidth
      />

      <TextInput
        source="relationshipHumansLongText"
        label="Kapcsolata az emberrel"
        multiline
        fullWidth
      />
      <TextInput
        source="habitateText"
        label="Jellemző élőhely"
        multiline
        fullWidth
      />
      <TextInput source="livingLongText" label="Életmód" multiline fullWidth />

      <TextInput
        source="dietLongText"
        label="Táplálkozás"
        multiline
        fullWidth
      />

      <TextInput
        source="protectedLongText"
        label="Védelmi helyzete"
        multiline
        fullWidth
      />

      <TextInput
        source="traceText"
        label="Nyomok leírása"
        multiline
        fullWidth
      />

      <TextInput
        source="behaviourLongText"
        label="Viselkedés"
        multiline
        fullWidth
      />
    </FormTab>

    <FormTab label="Rendszertani besorolás">
      <>
        <TextInput
          source="class"
          label="Osztály"
          style={{ width: 200, marginRight: 20 }}
        />
        <TextInput
          source="rank"
          label="Rend"
          style={{ width: 200, marginRight: 20 }}
        />
        <TextInput
          source="family"
          label="Család"
          style={{ width: 250, marginRight: 20 }}
        />
        <TextInput
          source="species"
          label="Faj"
          style={{ width: 250, marginRight: 20 }}
        />
      </>
    </FormTab>
    <FormTab label="Képek">
      <ImageInput
        style={{ width: '40px' }}
        source="mainImage"
        label="Fejléc"
        accept="image/jpeg"
        multiple={false}
      >
        <ImageField />
      </ImageInput>
      <ImageInput
        source="thumbnail"
        label="Thumbnail"
        accept="image/jpeg"
        multiple={false}
      >
        <ImageField height="100px" width="100px" circle />
      </ImageInput>
      <ArrayInput source="images" label="Képek">
        <SimpleFormIterator>
          <NumberInput
            source="order"
            label="Sorrend"
            max={9}
            min={0}
            validate={[minValue(0), maxValue(9)]}
          />
          <ImageInput
            source="url"
            label="Képek"
            multiple={false}
            accept="image/jpeg"
          >
            <ImageField />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>

    <FormTab label="Videók">
      <ArrayInput source="videos">
        <SimpleFormIterator>
          <NumberInput
            source="order"
            label="Sorrend"
            max={9}
            min={0}
            validate={[minValue(0), maxValue(9)]}
          />
          <TextInput source="id" label="Vimeo azonosító" />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  </TabbedForm>
)

export const edit = props => (
  <Edit aside={<Aside basePath={props.resource} />} {...props} undoable={false}>
    <AnimalForm />
  </Edit>
)

export const create = props => (
  <Create
    aside={<Aside basePath={props.resource} />}
    {...props}
    undoable={false}
    redirect="list"
  >
    <AnimalCreateForm />
  </Create>
)

const AnimalFilter = props => (
  <Filter {...props}>
    <TextInput source="id" label="ID" alwaysOn />
    <TextInput source="animalKey_like" label="key" alwaysOn />

    <TextInput source="class_like" label="Osztály" />
    <TextInput source="rank_like" label="Rend" />
    <TextInput source="family_like" label="Család" />
    <TextInput source="species_like" label="Faj" />

    <TextInput source="name_like" label="Név" alwaysOn />
    <TextInput source="nameLatin_like" label="Latin név" />
    <TextInput source="diet_like" label="Táplálkozás" />
    <SelectInput
      source="language"
      label="Nyelv"
      choices={[
        { id: 'HU', name: 'HU' },
        { id: 'EN', name: 'EN' },
      ]}
      defaultValue="HU"
      resettable
    ></SelectInput>
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filters={<AnimalFilter />}
    exporter={false}
    pagination={<ExtendedPagination />}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="language" label="Nyelv" />
      <TextField source="animalKey" label="key" />

      <TextField source="class" label="Osztály" />
      <TextField source="rank" label="Rend" />
      <TextField source="family" label="Család" />
      <TextField source="species" label="Faj" />

      <ImageField
        source="thumbnail"
        label="Thumbnail"
        width="50px"
        height="50px"
        circle
      />

      <TextField source="name" label="Név" />
      <TextField source="nameLatin" label="Latin név" />
      <TextField source="diet" label="Táplálkozás" />
    </Datagrid>
  </List>
)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const animal = {
  footprintImage: 'NYOM_denever_1_WHITE.png\nNYOM_denever_2_WHITE.png',
  imageUrl: '67KOZONSEGESDENEVER_FOTO_01  ',
}
