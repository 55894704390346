import { required } from 'ra-core'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  BooleanField,
  DateTimeInput,
  DateField,
  DateInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
// import { GMapInput } from '@fusionworks/ra-google-maps-input'

import { makeStyles } from '@material-ui/core/styles'
import { GMapInput } from 'tools/GMapInput'
import ImageField from 'tools/ImageField'
import Aside from 'resources/Aside'

// richtext input can't be styled properly: https://github.com/marmelab/react-admin/issues/3070
const useStyles = makeStyles({
  rtInput: {
    minHeight: '500px',
    background: 'rgba(0, 0, 0, 0.04)',
  },
})

const EventForm = props => {
  const classes = useStyles()
  return (
    <SimpleForm {...props} redirect="edit">
      <TextInput source="id" disabled style={{ width: '10rem' }} />
      <TextInput
        validate={required()}
        source="title"
        style={{ width: '40rem' }}
        label="Cím"
      />

      <TextInput
        source="lead"
        label="Alcím"
        validate={required()}
        style={{ width: '40rem' }}
      />

      <ImageInput
        source="image"
        label="Kép"
        multiple={false}
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField />
      </ImageInput>

      <ImageInput
        source="mobileImage"
        label="Mobil kép"
        multiple={false}
        accept="image/*"
        maxSize={5000000}
      >
        <ImageField />
      </ImageInput>

      <DateTimeInput label="Kezdete" source="startDateTime" />
      <DateTimeInput label="Vége" source="endDateTime" />
      <TextInput source="location" label="Helyszín" />
      <NumberInput source="longitude" label="Hosszúsági fok" />
      <NumberInput source="latitude" label="Szélességi fok" />

      <GMapInput
        googleKey="AIzaSyAxs4x00qJXm1Oai02r8FvVN7_789su8BE"
        latSource="latitude"
        lngSource="longitude"
        defaultCenter={{ lat: 47, lng: 19.5 }}
        defaultZoom={8}
      ></GMapInput>

      <RichTextInput
        validate={required()}
        source="content"
        label="Tartalom"
        variant={classes.rtInput}
        options={{
          modules: {
            history: {
              delay: 2000,
              maxStack: 500,
              userOnly: true,
            },
            toolbar: [
              [{ header: ['1', '2', '3', false] }],
              ['bold', 'italic', 'underline', 'link'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['clean'],
              ['image', 'video'],
            ],
          },
        }}
      />

      <DateTimeInput
        source="publishedFrom"
        resettable
        label="Publikálás kezdete"
      />
      <DateTimeInput source="publishedTo" resettable label="Publikálás vége" />
      <BooleanInput
        source="isPublished"
        label="Publikálva"
        defaultValue={true}
      ></BooleanInput>
      <BooleanInput source="isDeleted" label="Törölve" />

      <DateTimeInput label="Létrehozva" source="createdAt" disabled />
      <DateTimeInput label="Módosítva" source="updatedAt" disabled />
    </SimpleForm>
  )
}

export const create = props => (
  <Create aside={<Aside basePath={props.resource} />} {...props}>
    <EventForm />
  </Create>
)

export const edit = props => (
  <Edit aside={<Aside basePath={props.resource} />} {...props} undoable={false}>
    <EventForm />
  </Edit>
)

const EventFilter = props => (
  <Filter {...props}>
    <TextInput label="Cím" source="title_like" alwaysOn />

    <BooleanInput source="isDeleted" label="Törölt" alwaysOn />

    <ReferenceInput
      label="Létrehozó"
      source="createdBy.id"
      reference="adminUsers"
      alwaysOn
    >
      <SelectInput optionText="username" />
    </ReferenceInput>

    <DateInput label="Létrehozva ezután" source="createdAt_after" alwaysOn />
    <DateInput label="Időpont ezután" source="startDateTime_after" alwaysOn />
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filters={<EventFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    exporter={false}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="title" label="Cím" />
      <BooleanField source="isDeleted" label="Törölve" />
      <TextField source="createdBy.username" label="Létrehozó" />
      <DateField source="publishedFrom" label="Publikálás kezdete" />
      <DateField source="publishedTo" label="Publikálás vége" />
      <DateField source="startDateTime" label="Esemény kezdete" />
    </Datagrid>
  </List>
)
