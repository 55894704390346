import authProvider from './authProvider'
import customRoutes from './customRoutes'

import './App.css'
import { Admin, Layout, Resource } from 'react-admin'
import * as React from 'react'
import UserIcon from '@material-ui/icons/People'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import EventIcon from '@material-ui/icons/Event'
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit'
import PetsIcon from '@material-ui/icons/Pets'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import LocalPlayIcon from '@material-ui/icons/LocalPlay'
import CommentIcon from '@material-ui/icons/Comment'
import GitHubIcon from '@material-ui/icons/GitHub'

import TreeMenu from '@bb-tech/ra-treemenu'
import * as User from './resources/User'
import * as Article from './resources/Article'
import * as Event from './resources/Event'
import * as AdminUser from './resources/AdminUser'
import * as Block from './resources/Block'
import * as Pin from './resources/Pin'
import * as Comment from './resources/Comment'
import * as Animal from './resources/Animal'

import hungarianMessages from 'ra-language-hungarian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import Dashboard from './Dashboard'
import { createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import dataProvider from 'tools/dataProvider'
import PinDropIcon from '@material-ui/icons/PinDrop'
import AppBar from 'tools/AppBar'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#388e3c',
    },
    secondary: {
      main: '#00796b',
    },
    error: red,
  },
})

const i18nProvider = polyglotI18nProvider(() => hungarianMessages, 'hu', {
  allowMissing: true,
})

const App = () => ({
  render() {
    return (
      <Admin
        // menu={menu}
        locale="hu"
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        customRoutes={customRoutes}
        theme={theme}
        dataProvider={dataProvider}
        layout={props => <Layout {...props} menu={TreeMenu} appBar={AppBar} />}
        dashboard={Dashboard}
      >
        {permissions => [
          ...(permissions === 'admin'
            ? [
                <Resource
                  name="userblock"
                  key="userblock"
                  icon={UserIcon}
                  options={{ label: 'Felhasználók', isMenuParent: true }}
                />,
                <Resource
                  name="users"
                  key="users"
                  icon={UserIcon}
                  options={{
                    label: 'App felhasználók',
                    menuParent: 'userblock',
                  }}
                  {...User}
                />,
                <Resource
                  name="adminUsers"
                  key="adminUsers"
                  icon={PersonOutlineIcon}
                  options={{
                    label: 'Adminisztrátorok',
                    menuParent: 'userblock',
                  }}
                  {...AdminUser}
                />,
              ]
            : [
                <Resource name="adminUsers" key="adminUsers" />,
                <Resource name="users" key="users" />,
              ]),
          <Resource
            name="blocks"
            key="blocks"
            icon={ViewCarouselIcon}
            options={{ label: 'Tartalom kezelő' }}
            {...Block}
          />,
          <Resource
            name="articles"
            key="articles"
            options={{ label: 'Cikkek' }}
            icon={VerticalSplitIcon}
            {...Article}
          />,
          <Resource
            name="events"
            key="events"
            options={{ label: 'Eseményajánló' }}
            icon={EventIcon}
            {...Event}
          />,
          <Resource
            name="footprints"
            key="lábnyom"
            options={{ label: 'Lábnyom' }}
            icon={PetsIcon}
            {...Pin}
          />,
          <Resource
            name="traces"
            key="nyomtatlálat"
            options={{ label: 'Egyéb nyomtalálat' }}
            icon={PinDropIcon}
            {...Pin}
          />,
          <Resource
            name="places"
            key="érdekessegek"
            options={{ label: 'Érdekesség' }}
            icon={LocalPlayIcon}
            {...Pin}
          />,
          <Resource
            name="comments"
            key="kommentek"
            options={{ label: 'Hozzászólások' }}
            icon={CommentIcon}
            {...Comment}
          />,
          ...(permissions === 'admin'
            ? [
                <Resource
                  name="animals"
                  key="animals"
                  options={{ label: 'Állatok' }}
                  icon={GitHubIcon}
                  {...Animal}
                />,
              ]
            : [<Resource name="animals" key="animals" />]),
        ]}
      </Admin>
    )
  },
})

export default App
