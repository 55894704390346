import * as React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'

export default function Dashboard() {
  return (
    <Card>
      <CardHeader title="Welcome to the administration" />
      <CardContent>KOPÓ</CardContent>
    </Card>
  )
}
