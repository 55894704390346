/* eslint-disable react/prop-types */
import { FormDataConsumer, regex, required } from 'ra-core'
import React from 'react'
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  TabbedForm,
  TextField,
  TextInput,
  DateTimeInput,
  DateInput,
  NumberField,
  NumberInput,
  SelectInput,
  ReferenceInput,
  ImageInput,
  AutocompleteInput,
  ArrayInput,
  SimpleFormIterator,
  FormTab,
  SelectField,
} from 'react-admin'
import Aside from 'resources/Aside'
import { GMapInput } from 'tools/GMapInput'
import ImageField from 'tools/ImageField'
import VideoField from 'tools/VideoField'

const referenceTypeMap = {
  footprints: 'footprint',
  places: 'place',
  traces: 'trace',
}

const traceChoices = [
  { id: 0, name: 'Ürülék' },
  { id: 1, name: 'Kotorék' },
  { id: 2, name: 'Rágásnyom' },
  { id: 3, name: 'Túrás' },
  { id: 4, name: 'Agancs' },
  { id: 5, name: 'Szőr' },
  { id: 6, name: 'Kaparás' },
  { id: 7, name: 'Koponya' },
]

const placeChoices = [
  { id: 0, name: 'Látvány' },
  { id: 1, name: 'Forrás' },
  { id: 2, name: 'Tűzrakóhely' },
  { id: 3, name: 'Kilátó' },
  { id: 4, name: 'Magasles' },
  { id: 5, name: 'Barlang' },
  { id: 6, name: 'Büfé' },
  { id: 7, name: 'Túristaház' },
  { id: 8, name: 'Tanösvény' },
  { id: 9, name: 'Veszély' },
  { id: 10, name: 'Szemét' },
  { id: 11, name: 'Egyéb' },
]

const validCoordinate = regex(
  /-?[0-9]{1,3}[.][0-9]+/,
  'Must be a valid Coordinate'
)

const UserForm = props => (
  <TabbedForm {...props} redirect="edit">
    <FormTab label="Adatok">
      <TextInput source="id" disabled style={{ width: '20rem' }} />
      <TextInput source="title" label="Cím" validate={[required()]} />
      <DateTimeInput label="Létrehozva" source="createdDateTime" disabled />
      {/* TODO: formdataconsumer to update Google Map input based on coordinates */}
      <TextInput
        source="latNum"
        label="Szélességi fok"
        validate={[required(), validCoordinate]}
      />
      <TextInput
        source="lngNum"
        label="Hosszúsági fok"
        validate={[required(), validCoordinate]}
      />
      <GMapInput
        googleKey="AIzaSyAxs4x00qJXm1Oai02r8FvVN7_789su8BE"
        latSource="latNum"
        lngSource="lngNum"
        defaultCenter={{ lat: 47, lng: 19.5 }}
        defaultZoom={15}
      ></GMapInput>

      <SelectInput
        source="type"
        label="Típus"
        choices={[
          { id: 'place', name: 'Érdekesség' },
          { id: 'footprint', name: 'Lábnyom' },
          { id: 'trace', name: 'Egyéb nyom' },
        ]}
        validate={required()}
        defaultValue={referenceTypeMap[props.resource]}
      />

      <FormDataConsumer>
        {({ formData }) => {
          if (!formData) {
            return null
          }
          if (formData.type === 'place') {
            return (
              <SelectInput
                source="subtypeindex"
                label="Altípus"
                choices={placeChoices}
                validate={required()}
              />
            )
          }
          if (formData.type === 'trace') {
            return (
              <SelectInput
                source="subtypeindex"
                label="Altípus"
                choices={traceChoices}
                validate={required()}
              />
            )
          }
          if (formData.type === 'footprint') {
            return (
              <ReferenceInput
                validate={required()}
                reference="animals"
                source="subtypeindex"
                label="Állat"
                filterToQuery={searchText => ({
                  name_like: searchText,
                })}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
            )
          }
          return (
            <NumberInput
              label="Altípus"
              source="subtypeindex"
              validate={required()}
            ></NumberInput>
          )
        }}
      </FormDataConsumer>

      <TextInput source="note" label="Megjegyzés" />
      <TextInput source="userId" label="Rögzítette" disabled />
      <TextInput source="documentId" label="Másodlagos azonosító" disabled />

      <NumberInput source="coslat" label="Szélességi fok cos" disabled />
      <NumberInput source="sinlat" label="Szélességi fok sin" disabled />
      <NumberInput source="coslng" label="Hosszúsági fok cos" disabled />
      <NumberInput source="sinlng" label="Hosszúsági fok sin" disabled />
      <SelectInput
        source="status"
        label="Státusz"
        resettable
        choices={[
          { id: 'marked_for_admin_validation', name: 'Admin hitelesítés' },
          { id: 'marked_for_expert_validation', name: 'Szakértői hitelesítés' },
          {
            id: 'marked_for_expert_validation|marked_for_admin_validation',
            name: 'Szakértői és admin hitelesítés',
          },
        ]}
      />
      <TextInput source="slug" label="Slug" />
    </FormTab>

    <FormTab label="Képek">
      <ImageInput
        source="images"
        label="Képek"
        multiple={true}
        accept="image/*"
      >
        <ImageField />
      </ImageInput>
    </FormTab>

    <FormTab label="Videók">
      <ArrayInput source="videos" label="Videók">
        <SimpleFormIterator>
          <ImageInput
            source="video"
            label="Videó"
            accept="video/*"
            validate={[required()]}
          >
            <VideoField />
          </ImageInput>

          <ImageInput
            source="thumbnail"
            label="Előnézet"
            accept="image/*"
            validate={[required()]}
          >
            <ImageField />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  </TabbedForm>
)

export const create = props => (
  <Create aside={<Aside basePath={props.resource} />} {...props}>
    <UserForm />
  </Create>
)

export const edit = props => (
  <Edit aside={<Aside basePath={props.resource} />} {...props} undoable={false}>
    <UserForm />
  </Edit>
)

const UserFilter = props => (
  <Filter {...props}>
    <SelectInput
      alwaysOn
      source="status_like"
      label="Státusz"
      choices={[
        { id: 'marked_for_admin_validation', name: 'Admin hitelesítés' },
        { id: 'marked_for_expert_validation', name: 'Szakértői hitelesítés' },
        {
          id: 'marked_for_expert_validation|marked_for_admin_validation',
          name: 'Szakértői és admin hitelesítés',
        },
      ]}
    />
    <TextInput label="Cím" source="title_like" alwaysOn />
    <ReferenceInput
      reference="users"
      label="Rögzítő"
      source="userId"
      alwaysOn
      resettable
      perPage={100}
      sort={{ field: 'username', order: 'asc' }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <DateInput
      label="Létrehozva ezelőtt"
      resettable={true}
      source="datetime_before"
      clearAlwaysVisible={true}
      alwaysOn
    />
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filter={{ type: referenceTypeMap[props.resource] }}
    filters={<UserFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    exporter={false}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="title" label="Cím" />

      {/* <DateField source="createdAt" label="Létrehozva" /> */}
      <NumberField source="subtypeindex" label="Altípus" />
      <TextField source="userId" label="Rögzítette" />
      <TextField source="userName" label="Felhasználó" />

      <SelectField
        source="status"
        label="Státusz"
        choices={[
          { id: 'marked_for_admin_validation', name: 'Admin hitelesítés' },
          { id: 'marked_for_expert_validation', name: 'Szakértői hitelesítés' },

          {
            id: 'marked_for_expert_validation|marked_for_admin_validation',
            name: 'Szakértői és admin hitelesítés',
          },
        ]}
      />
      <NumberField source="imageCount" label="Csatolmányok" />
    </Datagrid>
  </List>
)
