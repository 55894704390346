/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Typography, Divider } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Button } from 'react-admin'

type AsideProps = {
  basePath: string
}

const Aside = ({ basePath }: AsideProps) => {
  if (!basePath) {
    return null
  }

  const resource = basePath.substr(1, basePath.length - 1)

  if (resource === 'users') {
    return <AppUsers />
  }

  if (resource === 'adminUsers') {
    return <AdminUsers />
  }

  if (resource === 'blocks') {
    return <Block />
  }

  if (resource === 'articles') {
    return <Article />
  }

  if (resource === 'animals') {
    return <Animal />
  }

  if (resource === 'events') {
    return <Event />
  }

  if (['places', 'traces', 'footprints'].includes(resource)) {
    return <Pin />
  }
  return null
}

const AppUsers = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  if (!isOpen) {
    return (
      <Button
        color="primary"
        onClick={toggle}
        key="open"
        style={{ alignItems: 'flex-start' }}
      >
        <ArrowBackIosIcon />
      </Button>
    )
  }

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Button color="primary" onClick={toggle} key="close">
        <ArrowForwardIosIcon />
      </Button>
      <Typography variant="h6">App felhasználók</Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Itt láthatóak a KOPÓ mobil applikáció regisztrált felhasználói a
        regisztráció módjától és a platformtól függetlenül.
      </Typography>
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        A felhasználók adatai kizárólag a felhasználó által szerkeszthetőek a
        mobil applikációban.
      </Typography>
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        A felhasználók jogosultságai rendszergazdai jogosultsággal
        módosíthatóak.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '10px' }} />

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Komment tiltva
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Nyomokhoz megjegyzés létrehozásának tiltása/engedélyezése
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Pin rögzítzés tiltva
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Nyom rögzítés tiltása/engedélyezése
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Toplistából kizárva
      </Typography>
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        Főoldali tartalomban szereplő bármilyen időszakra vonatkozó toplistában
        való megjelenés tiltása/engedélyezése (pl. adminok, szerkesztők)
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '8px' }} />
    </div>
  )
}

const AdminUsers = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  if (!isOpen) {
    return (
      <Button
        color="primary"
        onClick={toggle}
        key="open"
        style={{ alignItems: 'flex-start' }}
      >
        <ArrowBackIosIcon />
      </Button>
    )
  }

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Button color="primary" onClick={toggle} key="close">
        <ArrowForwardIosIcon />
      </Button>
      <Typography variant="h6">Adminisztrátorok</Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Ebben a modulban kezelhetőek a CMS felhasználó fiókok. Szerkesztő és
        Superadmin fiók létrehozása és módosítása csak Superadmin jogosultsággal
        lehetséges.
      </Typography>
      <Divider variant="fullWidth" style={{ marginTop: '10px' }} />

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Superadmin
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Ki
      </Typography>

      <ul>
        <li>
          <Typography variant="caption" key="Tartalomkezelő">
            Tartalomkezelő
          </Typography>
        </li>
        <li>
          <Typography variant="caption" key="Cikkek">
            Cikkek
          </Typography>
        </li>
        <li>
          <Typography variant="caption" key="Eseményajánló">
            Eseményajánló
          </Typography>
        </li>
        <li>
          <Typography variant="caption" key="Lábnyom">
            Lábnyom
          </Typography>
        </li>
        <li>
          <Typography variant="caption" key="Egyéb_nyomtalálat">
            Egyéb nyomtalálat
          </Typography>
        </li>
        <li>
          <Typography variant="caption" key="Érdekesség">
            Érdekesség
          </Typography>
        </li>
      </ul>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Be
      </Typography>

      <ul>
        <li>
          <Typography variant="caption" key="Tartalomkezelő">
            Szerkesztői modulok
          </Typography>
        </li>
        <li>
          <Typography variant="caption" key="Cikkek">
            App felhasználók
          </Typography>
        </li>
        <li>
          <Typography variant="caption" key="Eseményajánló">
            Adminisztrátorok
          </Typography>
        </li>
      </ul>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Zárolva
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Automatikusan zárolja rendszer a felhasználót 3 hibás bejelentkezési
        kísérlet után. A zárolást Superadmin tudja feloldani illetve szükség
        esetén új jelszót megadni.
      </Typography>
      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Törölve
      </Typography>
      <Typography variant="body2" style={{ marginTop: '1rem' }}>
        Törölt felhasználó nem fér hozzá a CMS rendszerhez. A
        törlés/visszaállítás Superadmin jogosultsága.
      </Typography>
      <Divider variant="fullWidth" style={{ marginTop: '8px' }} />
    </div>
  )
}

const Block = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  if (!isOpen) {
    return (
      <Button
        color="primary"
        onClick={toggle}
        key="open"
        style={{ alignItems: 'flex-start' }}
      >
        <ArrowBackIosIcon />
      </Button>
    )
  }

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Button color="primary" onClick={toggle} key="close">
        <ArrowForwardIosIcon />
      </Button>
      <Typography variant="h6">Tartalom kezelő</Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Itt kezelhetőek a mobil applikációban illetve a web applikációban a
        főoldalon megjelenő tartalmak (blokkok) és azok elemei.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '10px' }} />

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Sorrend
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        A blokkok a hozzájuk rendelt sorrend alapján növekvő sorrendben fentről
        lefelé jelennek meg az applikáció főoldalon. Blokkok sorrendjének
        módosítása a sorrend módosításával érhető el.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Újratöltési periódus
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        A blokkban lévő tartalom frissítésének gyakorisága másodpercben. Ha a
        blokkban szerepel “Top” vagy “Friss” típusú elem, akkor azt a főoldalon
        ilyen időközönként újra lekéri az applikáció.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Blokk típus
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Lista
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Blokkon belüli elemek vertikális elrendezése
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Slider
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Blokkon belüli elemek horizontális elrendezése
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Tartalom típusa
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Blokk elemben megjelenített tartalom típusa.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Mettől - Meddig
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Publikálás időtartama. Üresen hagyva időkorlát nélkül megjelenik a
        főoldalon a blokkon belül.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Publikálva
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Főoldali tartalom elrejtése/megjelenítése.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Megjelenített tartalom
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Top
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Nyom (Lábnyom, érdekesség, egyéb nyom) és Felhasználó típusú tartalmak
        Toplistája a kiválasztott pontgyűjtési időszak alatt elért pontok
        alapján.
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Friss
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        A mindenkori legfrissebben létrehozott elem a Tartalom típusnak
        megfelelően.
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Kitűzött
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Egy rögzített elem a Tartalom típusának megfelelően.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '8px' }} />
    </div>
  )
}

const Article = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  if (!isOpen) {
    return (
      <Button
        color="primary"
        onClick={toggle}
        key="open"
        style={{ alignItems: 'flex-start' }}
      >
        <ArrowBackIosIcon />
      </Button>
    )
  }

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Button color="primary" onClick={toggle} key="close">
        <ArrowForwardIosIcon />
      </Button>
      <Typography variant="h6">Cikkek</Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Ez a modul az applikációkban megjelenő hírek kezelését szolgálja. Az itt
        létrehozott és publikált hírek érhetőek el az applikáció “Hírek”
        menüpontjában vagy főoldali blokkokban is megjeleníthetőek.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '10px' }} />

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Megjelenési hely
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Cikkek
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        A hír megjelenik az applikációkban a “Hírek” menüpont alatt, de a
        “Nyomsuli” menüpont alatt nem
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Nyomsuli
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        A hír megjelenik az applikációkban a “Nyomsuli” menüpont alatt és a
        “Hírek” menüpont alatt is
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Alapértelmezett
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        A hír megjelenik az applikációkban a “Hírek” menüpont alatt, de a
        “Nyomsuli” menüpont alatt nem. További hír típusú applikáció menüpontok
        esetén van jelentősége.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Tartalom
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Másolt szöveg a formázás eltüntetése után a formázási menüsorban lévő
        gombokkal formázható. Képek csatolmányként illeszthetőek a
        szövegtörzsbe. Videók beillesztésekor Youtube illetve Vimeo link
        használható.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Publikálás kezdete - vége
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Applikációk menüpontjában való megjelenítés időtartama.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Publikálva
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Hír elrejtése/megjelenítése Hírek, Nyomsuli menüpontban vagy főoldali
        tartalomként.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Állat
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Kapcsolódó állatok kiválasztása az applikációban fellelhető állatok
        közül. Az applikációkban a kapcsolódó állat adatlapján megjelenik a
        kapcsolódó hír.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '8px' }} />
    </div>
  )
}

const Event = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  if (!isOpen) {
    return (
      <Button
        color="primary"
        onClick={toggle}
        key="open"
        style={{ alignItems: 'flex-start' }}
      >
        <ArrowBackIosIcon />
      </Button>
    )
  }

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Button color="primary" onClick={toggle} key="close">
        <ArrowForwardIosIcon />
      </Button>
      <Typography variant="h6">Eseményajánló</Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Ez a modul az applikációkban megjelenő események kezelését szolgálja. Az
        itt létrehozott és publikált események érhetőek el az applikáció
        “Események” menüpontjában vagy főoldali blokkokban is megjeleníthetőek.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '10px' }} />

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Kezdete - Vége
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Esemény kezdeti és záró időpontja.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Helyszín
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Esemény helyszíne, vagy online esemény esetén kapcsolódó weboldal URL,
        meeting link
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Hosszúsági és szélességi fok
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Esemény helyszínének koordinátái. A koordináták a térképen az esemény
        helyszínét kiválasztva automatikusan frissülnek.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Tartalom
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Másolt szöveg a formázás eltüntetése után a formázási menüsorban lévő
        gombokkal formázható. Képek csatolmányként illeszthetőek a
        szövegtörzsbe. Videók beillesztésekor Youtube illetve Vimeo link
        használható.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Publikálás kezdete - vége
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Applikációk események menüpontjában való megjelenítés időtartama.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Publikálva
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Esemény elrejtése/megjelenítése “Események” menüpontban vagy főoldali
        tartalomként.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '8px' }} />
    </div>
  )
}

const Animal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  if (!isOpen) {
    return (
      <Button
        color="primary"
        onClick={toggle}
        key="open"
        style={{ alignItems: 'flex-start' }}
      >
        <ArrowBackIosIcon />
      </Button>
    )
  }

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Button color="primary" onClick={toggle} key="close">
        <ArrowForwardIosIcon />
      </Button>
      <Typography variant="h6">Állatok</Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Ez a modul a mobil applikációban található állatok kezelését szolgálja.
        Állatok nem törölhetőek, illetve a hozzájuk tartozó key és id nem
        módosítható valamint új állatok sem hozhatóak létre.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '10px' }} />

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Adatok
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Alap adatok
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Ezen a fülön egy állat adatlap tetején látható ikonokkal ellátott adatok
        szerkeszthetőek.
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Rendszertani besorolás
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        A kiválasztott állat rendszertani besorolása. Az állat adatlap alján a
        kommentek felett található.
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Jellemzők
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Az állat és nyomának azonosítását szolgáló szöveges leírások.
      </Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Élőhely
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Az állat élőhelyével és földrajzi előfordulásával kapcsolatos adatok.
      </Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Életmód
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Az állat életmódjával és viselkedésével kapcsoaltos adatok.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Képek
      </Typography>

      <Typography variant="body1" style={{ marginTop: '8px' }}>
        Fejléc kép
      </Typography>

      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Az állat adatlap tetején rögzített fő kép.
      </Typography>

      <Typography variant="body1" style={{ marginTop: '8px' }}>
        Thumbnail
      </Typography>

      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Az állat listában látható 100px * 100px méretű kép.
      </Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Egy állathoz maximum 10 kép tartozhat. A sorrend 0-9 közötti különböző
        érték lehet minden egyes képnél.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Videók
      </Typography>

      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Egy állathoz maximum 10 videó tartozhat. A sorrend 0-9 közötti különböző
        érték lehet minden egyes videónál.
      </Typography>

      <Typography variant="body1" style={{ marginTop: '8px' }}>
        Sorrend
      </Typography>

      <Typography variant="caption" style={{ marginTop: '8px' }}>
        A sorrend határozza meg az állat adatlapon elhelyezett slider-ben
        elfoglalt pozíciót. Az első helyen álló videó sorrendje 0 legyen. Több
        videó csatolása esetén a sorrendnek fokozatosnak kell lennie.
      </Typography>

      <Typography variant="body1" style={{ marginTop: '8px' }}>
        Vimeo azonosító
      </Typography>

      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Csatolni kívánt video vimeo azonosítója száma.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '8px' }} />
    </div>
  )
}

const Pin = () => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  if (!isOpen) {
    return (
      <Button
        color="primary"
        onClick={toggle}
        key="open"
        style={{ alignItems: 'flex-start' }}
      >
        <ArrowBackIosIcon />
      </Button>
    )
  }

  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Button color="primary" onClick={toggle} key="close">
        <ArrowForwardIosIcon />
      </Button>
      <Typography variant="h6">Nyomtalálat</Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Itt kezelhetőek az applikáció felhasználók által rögzített nyomok,
        illetve új nyom a CMS-ből is létrehozható.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '10px' }} />

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Hosszúsági és szélességi fok
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Nyom találat koordinátái. A koordináták a térképen a nyom találat helyét
        kiválasztva automatikusan frissülnek.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Típus
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Érdekesség
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Állatokhoz nem kapcsolható egyéb érdekesség (kilátó, tűzrakóhely,
        tanösvény stb.)
      </Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Lábnyom
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Egy állathoz közvetlen kapcsolható lábnyom
      </Typography>

      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Egyéb nyom
      </Typography>
      <Typography variant="caption" style={{ marginTop: '8px' }}>
        Egyéb állattól származó, de konkrét állathoz nem kapcsolható nyom
        (ürülék, rágás, koponya stb.)
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Altípus / Állat
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Nyom találathoz kapcsolható altípus vagy lábnyom.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Státusz
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        Applikáció felhasználók kérhetik a lábnyom vagy egyéb nyom
        hitelesítését. Felülvizsgálat után a státusz üresre állítható.
      </Typography>

      <Typography align="center" variant="body1" style={{ marginTop: '15px' }}>
        Slug
      </Typography>
      <Typography variant="body2" style={{ marginTop: '8px' }}>
        A web applikációban a nyom adatlap url végződése.
      </Typography>

      <Divider variant="fullWidth" style={{ marginTop: '8px' }} />
    </div>
  )
}

export default Aside
