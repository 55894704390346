import { required } from 'ra-core'
import React from 'react'
import {
  Datagrid,
  DateField,
  Edit,
  Filter,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceInput,
  DateTimeInput,
  AutocompleteInput,
} from 'react-admin'

import Aside from 'resources/Aside'

const CommentForm = props => (
  <SimpleForm {...props} redirect="edit">
    <TextInput
      source="id"
      disabled
      style={{ width: '20rem' }}
      validate={required()}
    />
    <TextInput source="title" multiline fullWidth label="Komment" />

    <TextInput source="userName" label="Felhasználó" disabled />

    <TextInput source="reference" label="Referencia" disabled />
    <DateTimeInput source="createdDateTime" label="Létrehozva" disabled />
  </SimpleForm>
)

// export const create = props => (
//   <Create aside={<Aside basePath={props.resource} />} {...props}>
//     <CommentForm />
//   </Create>
// )

export const edit = props => (
  <Edit aside={<Aside basePath={props.resource} />} {...props} undoable={false}>
    <CommentForm />
  </Edit>
)

const CommentFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      reference="users"
      label="Rögzítő"
      source="userId"
      alwaysOn
      resettable
      perPage={100}
      sort={{ field: 'username', order: 'asc' }}
    >
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
  </Filter>
)

export const list = props => (
  <List
    aside={<Aside basePath={props.resource} />}
    {...props}
    filters={<CommentFilter />}
    hasCreate={false}
    exporter={false}
  >
    <Datagrid {...props} rowClick="edit">
      <TextField source="id" />
      <TextField source="userName" label="Felhasználó" />
      <TextField source="title" label="Komment" />
      <TextField source="reference" label="Referencia" />
      <TextField source="documentId" label="Referencia ID" />

      <DateField source="createdDateTime" label="Létrehozva" />
    </Datagrid>
  </List>
)
