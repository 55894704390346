import envConfig from 'envConfig'
import { decode } from 'jsonwebtoken'

export default {
  login: ({ username, password }) => {
    const request = new Request(`${envConfig.ApiUrl}/login`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ token }) => {
        const decodedToken = decode(token)
        localStorage.setItem('token', token)
        localStorage.setItem('userId', decodedToken.userId)
      })
      .catch(() => {
        throw new Error('Network error')
      })
  },

  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('role')
      return Promise.reject()
    }
    return Promise.resolve()
  },

  logout: () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('role')
    return Promise.resolve()
  },

  getIdentity: () => Promise.resolve({ id: localStorage.getItem('userId') }),
  // authorization

  getPermissions: () => {
    const role = decode(localStorage.getItem('token')).isAdmin
      ? 'admin'
      : 'normal'
    return role ? Promise.resolve(role) : Promise.reject()
  },
}
